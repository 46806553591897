import classNames from 'classnames';
import React, { useState } from 'react';
import { Icon } from '../../elements';

const FullScreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    const element = document.documentElement;
    // 如果是全屏状态
    if (isFullscreen) {
      // 如果浏览器有这个Function
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      // 如果浏览器有这个Function
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
    // 判断全屏状态的变量
    setIsFullscreen(!isFullscreen);
  };

  return (
    <div
      className={classNames('toolbar-button', isFullscreen && 'active')}
      onClick={toggleFullscreen}
    >
      <Icon name="fullscreen" />
      <div className="toolbar-button-label">全屏</div>
    </div>
  );
};

export default FullScreen;
