import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Thumbnail } from './Thumbnail.js';
import './StudyBrowser.styl';
import Icon from '../../elements/Icon/Icon.js';

function StudyBrowser(props) {
  const {
    studies,
    onThumbnailClick,
    onThumbnailDoubleClick,
    supportsDrag,
    showThumbnailProgressBar,
    viewportSpecificData,
  } = props;

  const [studiesOpen, setStudiesOpen] = useState({});

  const toggleOpen = studyUid => {
    setStudiesOpen({ ...studiesOpen, [studyUid]: !studiesOpen[studyUid] });
  };

  useEffect(() => {
    if (
      studies &&
      studies.length &&
      !Object.keys(studiesOpen).length &&
      viewportSpecificData[0]
    ) {
      setStudiesOpen(
        studies.reduce((res, item) => {
          res[item.StudyInstanceUID] =
            item.StudyInstanceUID === viewportSpecificData[0].StudyInstanceUID;
          return res;
        }, {})
      );
    }
  }, [studies, studiesOpen, viewportSpecificData]);

  return (
    <div className="study-browser">
      <div className="scrollable-study-thumbnails">
        {studies
          .map((study, studyIndex) => {
            const { StudyInstanceUID, studyInfo } = study;
            const { Modality, StudyDate, displaySets } = studyInfo;

            const seriesLength = displaySets ? displaySets.length : '-';
            const framesLength = displaySets
              ? displaySets.reduce((res, item) => res + item.numImageFrames, 0)
              : '-';

            const isOpen = studiesOpen[StudyInstanceUID];

            return (
              <div key={studyIndex} className="study-thumbnail-group">
                <div
                  className="study-info"
                  onClick={() => toggleOpen(StudyInstanceUID)}
                >
                  <div className="study-info-title">
                    {StudyDate}{' '}
                    <span className="study-info-modality">{Modality}</span>
                    <div className="study-info-title-desc">
                      {seriesLength}个序列{' '}
                      {isNaN(framesLength) ? '-' : framesLength}
                      张影像
                    </div>
                  </div>
                  <Icon
                    name="chevron-down"
                    className={
                      isOpen
                        ? 'chevron-down'
                        : 'chevron-down chevron-down-right'
                    }
                  />
                </div>
                <div
                  className="study-info-thumbnails"
                  style={isOpen ? {} : { display: 'none' }}
                >
                  {study.thumbnails.map((thumb, thumbIndex) => {
                    // TODO: Thumb has more props than we care about?
                    const {
                      active,
                      altImageText,
                      displaySetInstanceUID,
                      imageId,
                      derivedDisplaySetsNumber,
                      numImageFrames,
                      SeriesDescription,
                      SeriesNumber,
                      hasWarnings,
                      hasDerivedDisplaySets,
                    } = thumb;

                    return (
                      <div
                        key={thumb.displaySetInstanceUID}
                        className="thumbnail-container"
                        data-cy="thumbnail-list"
                      >
                        <Thumbnail
                          active={active}
                          supportsDrag={supportsDrag}
                          key={`${studyIndex}_${thumbIndex}`}
                          id={`${studyIndex}_${thumbIndex}`} // Unused?
                          // Study
                          StudyInstanceUID={StudyInstanceUID} // used by drop
                          // Thumb
                          altImageText={altImageText}
                          imageId={imageId}
                          derivedDisplaySetsNumber={derivedDisplaySetsNumber}
                          displaySetInstanceUID={displaySetInstanceUID} // used by drop
                          numImageFrames={numImageFrames}
                          SeriesDescription={SeriesDescription}
                          SeriesNumber={SeriesNumber}
                          hasWarnings={hasWarnings}
                          hasDerivedDisplaySets={hasDerivedDisplaySets}
                          // Events
                          onClick={onThumbnailClick.bind(
                            undefined,
                            displaySetInstanceUID
                          )}
                          onDoubleClick={onThumbnailDoubleClick}
                          showProgressBar={showThumbnailProgressBar}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
          .flat()}
      </div>
    </div>
  );
}

const noop = () => {};

StudyBrowser.propTypes = {
  studies: PropTypes.arrayOf(
    PropTypes.shape({
      StudyInstanceUID: PropTypes.string.isRequired,
      thumbnails: PropTypes.arrayOf(
        PropTypes.shape({
          altImageText: PropTypes.string,
          displaySetInstanceUID: PropTypes.string.isRequired,
          imageId: PropTypes.string,
          derivedDisplaySetsNumber: PropTypes.number,
          numImageFrames: PropTypes.number,
          SeriesDescription: PropTypes.string,
          SeriesNumber: PropTypes.number,
          stackPercentComplete: PropTypes.number,
        })
      ),
    })
  ).isRequired,
  supportsDrag: PropTypes.bool,
  onThumbnailClick: PropTypes.func,
  onThumbnailDoubleClick: PropTypes.func,
  showThumbnailProgressBar: PropTypes.bool,
};

StudyBrowser.defaultProps = {
  studies: [],
  supportsDrag: true,
  onThumbnailClick: noop,
  onThumbnailDoubleClick: noop,
  showThumbnailProgressBar: true,
};

export { StudyBrowser };
