import './OHIFLogo.css';

import React from 'react';

import logo from './logo.png';

function OHIFLogo() {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="header-brand"
      href="http://ohif.org"
    >
      <img src={logo} className="header-logo-img" alt="logo" />
      {/* <Icon name="ohif-logo" className="header-logo-image" /> */}
      {/* Logo text would fit smaller displays at two lines:
       *
       * Open Health
       * Imaging Foundation
       *
       * Or as `OHIF` on really small displays
       */}
      {/* <Icon name="ohif-text-logo" className="header-logo-text" /> */}
    </a>
  );
}

export default OHIFLogo;
