import csTools from 'cornerstone-tools';

// import triggerEvent from './util/triggerEvent.js';
// // TODO
// import { moveHandleNearImagePoint } from '../../util/findAndMoveHelpers.js';
// // TODO
// import pointInsideBoundingBox from '../../util/pointInsideBoundingBox.js';
// // TODO
// import calculateSUV from '../../util/calculateSUV.js';
import numbersWithCommas from '../util/numbersWithCommas.js';
import getPixelSpacing from '../util/getPixelSpacing';
// // TODO
// import { clipToBox } from '../../util/clip.js';
// // TODO
// import freehandUtils from '../../util/freehand/index.js';
// // TODO
// import throttle from '../../util/throttle';

// // Drawing
import { getNewContext, draw, drawJoinedLines } from '../drawing/index.js';
import drawLinkedTextBox from '../drawing/drawLinkedTextBox.js';
import drawHandles from '../drawing/drawHandles.js';
// import { hideToolCursor, setToolCursor } from '../../store/setToolCursor.js';
// import { freehandRoiCursor } from '../cursors/index.js';

import { moveNewHandle } from '../manipulators';
import triggerEvent from '../util/triggerEvent.js';

const {
  EVENTS,
  external,
  FreehandRoiTool: BaseAnnotationTool,
  addToolState,
  getToolState,
  removeToolState,
  toolStyle,
  toolColors,
  getModule,
  store,
} = csTools;

// const { state } = store;

// const {
//   insertOrDelete,
//   freehandArea,
//   calculateFreehandStatistics,
//   freehandIntersect,
//   FreehandHandleData,
// } = freehandUtils;

/**
 * @public
 * @class FreehandRoiTool
 * @memberof Tools.Annotation
 * @classdesc Tool for drawing arbitrary polygonal regions of interest, and
 * measuring the statistics of the enclosed pixels.
 * @extends Tools.Base.BaseAnnotationTool
 */
// export default class FreehandRoiTool extends BaseAnnotationTool {
export default class FreehandRoiTool extends BaseAnnotationTool {
  constructor(props = {}) {
    const defaultProps = {
      name: 'FreehandRoi',
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: defaultFreehandConfiguration(),
    };

    super(defaultProps, props);

    // console.log('=====freehand 2', props);
  }

  renderToolData(evt) {

    const eventData = evt.detail;

    // If we have no toolState for this element, return immediately as there is nothing to do
    const toolState = getToolState(evt.currentTarget, this.name);
    // console.log('=====freehand 3', toolState);

    if (!toolState) {
      return;
    }

    const { image, element } = eventData;
    const config = this.configuration;
    const seriesModule = external.cornerstone.metaData.get(
      'generalSeriesModule',
      image.imageId
    );
    const modality = seriesModule ? seriesModule.modality : null;

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);
    const lineWidth = toolStyle.getToolWidth();
    const { renderDashed } = config;
    const lineDash = getModule('globalConfiguration').configuration.lineDash;

    for (let i = 0; i < toolState.data.length; i++) {
      const data = toolState.data[i];

      if (data.visible === false) {
        continue;
      }

      draw(context, context => {
        let color = toolColors.getColorIfActive(data);
        let fillColor;

        if (data.active) {
          if (data.handles.invalidHandlePlacement) {
            color = config.invalidColor;
            fillColor = config.invalidColor;
          } else {
            color = toolColors.getColorIfActive(data);
            fillColor = toolColors.getFillColor();
          }
        } else {
          fillColor = toolColors.getToolColor();
        }

        let options = { color };

        if (renderDashed) {
          options.lineDash = lineDash;
        }

        if (data.handles.points.length) {
          const points = data.handles.points;

          drawJoinedLines(context, element, points[0], points, options);

          if (data.polyBoundingBox) {
            drawJoinedLines(
              context,
              element,
              points[points.length - 1],
              [points[0]],
              options
            );
          } else {
            drawJoinedLines(
              context,
              element,
              points[points.length - 1],
              [config.mouseLocation.handles.start],
              options
            );
          }
        }

        // Draw handles

        options = {
          color,
          fill: fillColor,
        };

        if (config.alwaysShowHandles || (data.active && data.polyBoundingBox)) {
          // Render all handles
          options.handleRadius = config.activeHandleRadius;

          if (this.configuration.drawHandles) {
            drawHandles(context, eventData, data.handles.points, options);
          }
        }

        if (data.canComplete) {
          // Draw large handle at the origin if can complete drawing
          options.handleRadius = config.completeHandleRadius;
          const handle = data.handles.points[0];

          if (this.configuration.drawHandles) {
            drawHandles(context, eventData, [handle], options);
          }
        }

        if (data.active && !data.polyBoundingBox) {
          // Draw handle at origin and at mouse if actively drawing
          options.handleRadius = config.activeHandleRadius;

          if (this.configuration.drawHandles) {
            drawHandles(
              context,
              eventData,
              config.mouseLocation.handles,
              options
            );
          }

          const firstHandle = data.handles.points[0];

          if (this.configuration.drawHandles) {
            drawHandles(context, eventData, [firstHandle], options);
          }
        }

        // Update textbox stats
        if (data.invalidated === true && !data.active) {
          if (data.meanStdDev && data.meanStdDevSUV && data.area) {
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            this.updateCachedStats(image, element, data);
          }
        }

        // Only render text if polygon ROI has been completed and freehand 'shiftKey' mode was not used:
        if (data.polyBoundingBox && !data.handles.textBox.freehand) {
          // If the textbox has not been moved by the user, it should be displayed on the right-most
          // Side of the tool.
          if (!data.handles.textBox.hasMoved) {
            // Find the rightmost side of the polyBoundingBox at its vertical center, and place the textbox here
            // Note that this calculates it in image coordinates
            data.handles.textBox.x =
              data.polyBoundingBox.left + data.polyBoundingBox.width;
            data.handles.textBox.y =
              data.polyBoundingBox.top + data.polyBoundingBox.height / 2;
          }

          if (data.text === undefined) {
            this.configuration.getTextCallback(text => {
              data.text = text;
              drawLinkedTextBox(
                context,
                element,
                data.handles.textBox,
                text,
                data.handles.points,
                textBoxAnchorPoints,
                color,
                lineWidth,
                0,
                true
              );
            }, evt.detail);
          } else {
            drawLinkedTextBox(
              context,
              element,
              data.handles.textBox,
              data.text,
              data.handles.points,
              textBoxAnchorPoints,
              color,
              lineWidth,
              0,
              true
            );
          }
        }
      });
    }

    function textBoxAnchorPoints(handles) {
      return handles;
    }
  }
}

function defaultFreehandConfiguration() {
  return {
    mouseLocation: {
      handles: {
        start: {
          highlight: true,
          active: true,
        },
      },
    },
    spacing: 1,
    activeHandleRadius: 3,
    completeHandleRadius: 6,
    completeHandleRadiusTouch: 28,
    alwaysShowHandles: false,
    invalidColor: 'crimson',
    currentHandle: 0,
    currentTool: -1,
    drawHandles: true,
    renderDashed: false,
    getTextCallback,
    changeTextCallback,
  };
}

function getTextCallback(doneChangingTextCallback) {
  doneChangingTextCallback(prompt('Enter your annotation:'));
}

function changeTextCallback(data, eventData, doneChangingTextCallback) {
  doneChangingTextCallback(prompt('Change your annotation:'));
}

function preventPropagation(evt) {
  evt.stopImmediatePropagation();
  evt.stopPropagation();
  evt.preventDefault();
}
